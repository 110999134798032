<template>
  <mtCard
      :icon="dataTask.icon.name"
      :icon-color="dataTask.icon.color"
      :icon-bg-color="dataTask.icon.bgColor"
      :class="computedClasses"
  >
    <template #header>
      <div class="test-card__header-row">
        <mtChip class="test-card__type">{{ dataTask.type.label }}</mtChip>
        <button
            class="test-card__download-btn"
            v-if="dataTask.isAvailableForExport"
            @click="openDownloadReportPopover()"
        >
          <mtSvg
              class="test-card__download-btn__icon"
              name="download"
              width="10"
              height="14"
          />
        </button>
      </div>
    </template>
    <template #content>
      <div class="test-card__text">
        <h2 class="test-card__title">{{ dataTask.name }}</h2>
        <mtShowMoreText
            class="test-card__description"
            height="67px"
        >
          <p>{{ dataTask.description }}</p>
        </mtShowMoreText>
        <plan-test-time :item="dataTask"/>
        <div
            class="test-card__info"
            v-if="dataTask.group"
        >
          <mtSvg
              class="test-card__info__icon"
              name="group"
              color="#00788C"
              width="15"
          />
          <p>Группа: {{ dataTask.group }}</p>
        </div>
        <div
            :class="['test-card__info', {
              'test-card__info--green': dataTask.attemptsAmount > 0 && dataTask.attemptsAmount > dataTask.attempts,
            }]"
        >
          <mtSvg
              class="test-card__info__icon"
              name="refresh-bg"
              color="#00788C"
              width="15"
          />
          <p v-if="dataTask.attemptsAmount === -1">Кол-во попыток: ∞</p>
          <p v-else>Использовано попыток: {{ dataTask.attempts }} из {{ dataTask.attemptsAmount }}</p>
        </div>
        <file-download-card :links="dataTask.materials"/>
      </div>
      <div class="test-card__footer">
        <div
            class="test-card__points"
            v-if="(test.balls && test.balls.current !== 0) && !test.isCalculating && show_progress"
        >
          Баллы: {{ test.balls.current }} из {{ test.balls.total }}
        </div>
        <mt-button
            :class="[
                'test-card__button',
                'test-card__button_' + dataTask.btn.className
              ]"
            :disabled="!dataTask.isOpened && !dataTask.isFailed"
            :loading="dataTask.btn.loading"
            @click="handler()"
        >
          <template
              #icon
              v-if="dataTask.btn.icon"
          >
            <mtSvg
                :name="dataTask.btn.icon"
                color="#9FA2B4"
            />
          </template>
          <template #default>
            {{ dataTask.btn.label }}
          </template>
        </mt-button>
      </div>
    </template>
  </mtCard>
  <teleport to="body">
    <DownloadReportPopover
        :visibility="showDownloadReportPopup"
        :reports="dataTask.reports"
        @close="closeDownloadReportPopover"
        @download="downloadReport"
    />
  </teleport>
</template>

<script>
import mtShowMoreText from "@/components/UI/mtShowMoreText/index.vue";
import mtCard from "@/components/UI/mtCard/index.vue";
import mtSvg from '@/components/UI/mtSvg/index.vue';
import mtButton from '@/components/UI/mtButton/index.vue';
import mtChip from '@/components/UI/mtChip/index.vue';
import {TASK_TYPE_CONFIRMATION, TASK_TYPE_EXPERT, TASK_TYPE_TEST, TASK_TYPE_TEST_PRO} from '@/constants/testTypes';
import {mapActions, mapGetters} from 'vuex';
import MtCheckbox from '@/components/UI/mtCheckbox/index.vue';
import FileDownloadCard from '@/components/workspace/assessmentPlans/plans/fileDownloadCard/index.vue';
import DownloadReportPopover from "@/components/workspace/testLibrary/results/DownloadReportPopover/index.vue";
import PlanTestTime from '@/components/workspace/assessmentPlans/planTestTime/index.vue';
import externalApi from '@/network/external';
import api from '@/network';

export default {
  name: "testCard",
  components: {
    DownloadReportPopover,
    PlanTestTime,
    FileDownloadCard,
    MtCheckbox,
    mtChip,
    mtButton,
    mtSvg,
    mtCard,
    mtShowMoreText
  },
  props: {
    test: {
      type: Object,
      default: () => ({
        icon: '',
        type: '',
        title: '',
        description: '',
        deadline: '',
        group: '',
        attempts: '',
        department: '',
        file: ''
      })
    },
    show_progress: Boolean,
  },
  data: () => {
    return {
      clipboardPlans: [],
      clipboardUuid: null,

      selectedResultsReport: [],

      TASK_TYPE_CONFIRMATION,
      dataTask: {},
      showDownloadReportPopup: false,
    }
  },
  computed: {
    ...mapGetters(['authUser']),

    computedClasses() {
      return [
        'test-card',
        'test-card-type--' + this.dataTask.type.value,
        'test-card-status--' + this.dataTask.icon.className
      ]
    }
  },
  methods: {
    ...mapActions('assessmentPlans/plan/task', ['startTaskSession', 'confirmTask']),

    openDownloadReportPopover() {

      this.selectedResultsReport = [{'uuid_session': this.dataTask.sessionUuid, 'uuid_user': this.dataTask.userUuid}];

      this.showDownloadReportPopup = true;
    },
    closeDownloadReportPopover() {
      this.selectedResultsReport = [];
      // this.clipboardGender = null;
      // this.clipboardFullName = null;
      this.showDownloadReportPopup = false;
    },

    async downloadReport({format, variants, titles}) {
      const payload = [
        {
          format,
          variants,
          selected_results_report: this.selectedResultsReport
        }
      ];

      const response = await api.post('export/results', payload);
      if (response.data?.url) {

        const reportLink = response.data.url;
        const fileNameInfo = response.data.FileNameInfo;
        const isGroup = variants.length > 1;

        if (isGroup) {
          window.open(reportLink, "_blank");
        } else {
          const response = await externalApi.get(`${ reportLink }`, {
            responseType: 'blob',
          });

          if (response.data.size) {
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');

            link.href = url;

            //let fileName = `results__${ new Date().toLocaleDateString() }-${ new Date().toLocaleTimeString().replace(/:/g, '_') }.${ type }`;
            let fileName = fileNameInfo['FullName'] + '_' + fileNameInfo['TestName'] + '_' +titles[0] + '_' +fileNameInfo['TestNumber'] + '.' + format;

            link.setAttribute('download', fileName);
            document.body.appendChild(link);
            link.click();
          } else {
            this.$toast.error('Отчёт не найден');
          }
        }
      }
    },
    handler() {
      const planUuid = this.$route.params.planUuid;
      const taskUuid = this.dataTask.uuid;

      if (this.dataTask.type.value === TASK_TYPE_TEST || this.dataTask.type.value === TASK_TYPE_TEST_PRO) {
        this.dataTask.btn.loading = true;
        this.startTaskSession({planUuid, taskUuid})
            .then(response => {
              const url = response.data.url;
              window.open(url, '_blank').focus();
              if(this.dataTask.btn.label === 'Начать выполнение') {
                this.dataTask.btn.label = 'Продолжить выполнение';
              }
            })
            .catch(errorResponse => {
              this.$toast.error(errorResponse.data?.message);
            })
            .finally(() => {
              this.dataTask.btn.loading = false;
            })
      } else if (this.dataTask.type.value === TASK_TYPE_EXPERT) {
        this.$router.push({
          name: 'RespondentTaskSingle',
          params: {
            planUuid: this.$route.params.planUuid,
            taskUuid: this.dataTask.uuid
          }
        })
      }
    }
  },
  created() {
    this.dataTask = this.test;
  }
}
</script>

<style
    lang="scss"
    src="./style.scss"
/>